import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SeoComponent from "../components/seo"

const ThankYouPage = () => (
	<Layout>
		<SeoComponent title="Thank you!" />
		<div className="aux-page">
			<div className="container text-center">
				<h1 className="font-weight-bold text-white">Thank you!</h1>
				<p className="text-white">
					We have received your message and will get back to you soon.
				</p>
				<Link to="/" className="btn btn-primary">
					Return Home
				</Link>
			</div>
		</div>
	</Layout>
)

export default ThankYouPage
